import React from 'react'
import { FaLocationDot } from 'react-icons/fa6'
import { IoCall } from 'react-icons/io5'
import { MdEmail } from "react-icons/md";

export default function Footer() {
  return (
    <>
      <div className='footer footer-bg '>
       
    

        <div className='container'>

        <div className='row'>

        <div className='col-md-4 align-items-center justify-content-center d-flex'>
        <img src="/Assets/Logo.png" width="250" height="55" alt="logo" />
        
        
        </div>
        <div className='col-md-4'>

        <h1 className='servfooter mt-4 mb-5'>OUR SERVICES</h1>

        <p className=' hovereffect'>EDUCATION AND RECRUITMENT SERVICES</p>
        <p  className='hovereffect'>IATA TRAINING&AIRPORT MANAGEMENT TRAINING</p>
        <p className='hovereffect'>COLLEGE ADMISSION ASSISTANCE</p>
        <p className='hovereffect'> TEST PREPARATION (IELTS)</p>
        <p className='hovereffect'>CAREER COUNSELING</p>
        <p className='hovereffect'>STUDY ABROAD PROGRAMS</p>
        <p className='hovereffect'>SKILL DEVELOPMENT WORKSHOPS</p>
        <p className='hovereffect'>TRAINING PROGRAMS</p>
        <p className='hovereffect'>TICKETING&VISA PROCESSING</p>
        <p className='hovereffect'>MEDICAL TOURISM</p>
        <p className='hovereffect'>RECRUITMENT SERVICES</p>
        
        </div>
        <div className='col-md-4'>
        <h1 className='servfooter mt-4 mb-5'>CONTACT INFO</h1>
        <a href="tel:+91 9061268938">
        <p className='text-black'> <IoCall  color='black' /> India 9061268938</p>
        </a>
        <a href="tel:+96878415829 ">
        <p className='text-black'> <IoCall  color='black' /> Muscat +96878415829 </p>
        </a>
        <div className="d-flex align-items-center ">
      
        <a  href="mailto:choicerecruitmentservices@gmail.com">
          <p className="text-black">
          <MdEmail /> choicerecruitmentservices@gmail.com
          </p>
        </a>
      </div>
        <p className='text-black'> <FaLocationDot   color='black' />  Al khuwair
        Sultan qaboos road
        <br /> Way no.3513
        Muscat114
        Oman</p> 
        
        </div>
        
        </div>
        
        </div>
      
      </div>
      <div className='copy-right'>
        <div className='copy-right-text'>© copyrights 2024</div>
      </div>
    </>
  )
}
