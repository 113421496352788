import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp, faInstagram, faFacebook, faTwitter, faXTwitter } from '@fortawesome/free-brands-svg-icons';


const SocialMediaSidebar = () => {
  return (
    <div className="social-media-sidebar">
      <a href="https://wa.me/your-number" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faWhatsapp} size="2x" />
      </a>
      <a href="https://www.instagram.com/yourprofile" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faInstagram} size="2x" />
      </a>
      <a href="https://www.facebook.com/yourprofile" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faFacebook} size="2x" />
      </a>
      <a href="https://twitter.com/yourprofile" target="_blank" rel="noopener noreferrer">
      <FontAwesomeIcon icon={faXTwitter} size="2x" />
      </a>
    </div>
  );
};

export default SocialMediaSidebar;
