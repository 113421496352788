import React from 'react';
import { useLocation } from 'react-router-dom';

export default function Header() {
  const location = useLocation();
  const currentPath = location.pathname;

  return (
    <nav
      className="head-footer-bg  heighthead sticky-header navbar navbar-expand-lg"
      id="customnavbar"
    >
      <div className="container fullwidth">
        <a href="/" className="navbar-brand">
          <img src="/Assets/Logo.png" width="250" height="55" alt="logo" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse " id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto  "> {/* Align items to the right */}
            <li className="nav-item me-4">
              <a
                className={`nav-a ${currentPath === "/" ? "active" : ""}`}
                href="/"
              >
                HOME
              </a>
            </li>

            <li className="nav-item dropdown me-4">
              <a
                className="nav-a dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                SERVICES
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a className="dropdown-item" href="/">
                    AVIATION
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="/">
                  MANPOWER
                  </a>
                </li>

                <li>
                <a className="dropdown-item" href="/">
                EDUCATION AND RECRUITMENT 
                </a>
              </li>
              <li>
              <a className="dropdown-item" href="/">
              IATA TRAINING AIRPORT MANAGEMENT TRAINING
              </a>
            </li>
            <li>
            <a className="dropdown-item" href="/">
           AIRPORT MANAGEMENT TRAINING
            </a>
          </li>
            <li>
            <a className="dropdown-item" href="/">
            COLLEGE ADMISSION ASSISTANCE
            </a>
          </li>

          <li>
          <a className="dropdown-item" href="/">
          TEST PREPARATION (IELTS)
          </a>
        </li>

        <li>
        <a className="dropdown-item" href="/">
        CAREER COUNSELING
        </a>
      </li>

      <li>
      <a className="dropdown-item" href="/">
      RECRUITMENT SERVICES
      </a>
    </li>

    <li>
    <a className="dropdown-item" href="/">
    MEDICAL TOURISM
    </a>
  </li>
  <li>
  <a className="dropdown-item" href="/">
  TICKETING & VISA PROCESSING
  </a>
</li>
<li>
<a className="dropdown-item" href="/">
TRAINING PROGRAMS
</a>
</li>
<li>
<a className="dropdown-item" href="/">
SKILL DEVELOPMENT WORKSHOPS
</a>
</li>
<li>
<a className="dropdown-item" href="/">
STUDY ABROAD PROGRAMS
</a>
</li>
              </ul>
            </li>

         
        
           

            <li className="nav-item me-4">
              <a
                className={`nav-a ${currentPath === "/Aviation" ? "active" : ""}`}
                href="/Aviation"
              >
                CAREER
              </a>
            </li>
            <li className="nav-item me-4">
            <a
              className={`nav-a ${currentPath === "/Manpower" ? "active" : ""}`}
              href="/Manpower"
            >
              MANPOWER
            </a>
          </li>

            <li className="nav-item">
              <a
                className={`nav-a ${currentPath === "/contact-us" ? "active" : ""}`}
                href="/#contactus"
              >
                CONTACT US
              </a>
            </li>

         
          </ul>
        </div>
      </div>
    </nav>
  );
}
