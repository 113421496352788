import React from 'react'
import '@splidejs/react-splide/css';
import CountUp from 'react-countup';
import { RiArrowRightSLine } from "react-icons/ri";
import SocialMediaSidebar from './components/SocialMediaSidebar';
import ScrollArrow from './components/Scrollarrow';
import EnquiryForm from './components/EnquiryForm';

export default function Home() {
  return (
    <>
    <head>
      <title>Aviation</title>
    </head>
    <SocialMediaSidebar/>

    <ScrollArrow/>
      {/* Hero banner design */}
      <div className='section herobanner'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-8'>
              <div className='inner-text'>
                <h1>Aviation Courses <br />and Workforce: Charting <br />Opportunities in the Skies</h1>
                <p>Aviation Course and Workforce: Charting Opportunities in the Skies" refers to the strategic planning and development of educational programs and employment prospects within the aviation industry. It emphasizes the exploration and creation of career pathways for individuals aspiring to work in various roles related to aviation.</p>
                <a href="/#contactus"
                  type="button"
                  className="mt-2 btn  btn-rounded btn-lg bounce">
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </div>
       
      </div>

     
      {/* Services */}
      <div className='section  '>
        <div className='container '>
        <h1 className='mb-5 text-center '>OUR EXPERTISE </h1>
        <div className='col-12 align-items-center d-flex justify-content-center'>
          <div className='row  col-md-10  '>
         <a href='/Aviation' className='col-6 align-items-center d-flex justify-content-center'>
            <div  className='bounce'>
              <img src='/Assets/aviation.png' className='Servicesimage' alt='' />
            </div>
            </a>
            <a href='/Manpower' className='col-6 align-items-center d-flex justify-content-center'>
            <div  className='bounce'>
            <img src='/Assets/HR.png' className='Servicesimage' alt=''  />
            </div>
            </a>
            </div>
          </div>
        </div>
      </div>

      
      


       {/* Avation */}
       <div className='section two-right'>
       <div className='container'>
         <div className='row'>
           <div className='col-lg-6'>
             <img src='/Assets/Aviimg.jpg' className='bg-exp-data' alt='' />
           </div>
           <div className='col-lg-6'>
             <div className='inner-two'>
               <h6 className='mt-4'>What We do</h6>
               <h3>Elevate Your Career with Expert <br/> Aviation Training</h3>
               <p>Discover a range of aviation courses designed to equip you with the skills needed for a successful career in the skies.
               Our programs feature state-of-the-art training facilities and experienced instructors to guide you every step of the way.
               Whether you're aiming to become a pilot, engineer, or air traffic controller, our courses provide the foundation for your professional journey.</p>
             </div>
           </div>
         </div>
       </div>
     </div>


       {/* manpower  */}
       <div className='section two-'>
       <div className='container'>
         <div className='row'>
         <div className='col-lg-6'>
         <div className='inner-two'>
           <h6>What We do</h6>
           <h3>Achieve Excellence with Our Tailored <br/>Manpower Solutions</h3>
           <p>Our tailored manpower solutions are crafted to meet your unique staffing and workforce needs effectively.
           We provide expert services in recruitment, employee training, and workforce management to help you build a successful team.
           Choose us to access top talent and innovative solutions that drive your organization's success and growth.</p>
         </div>
       </div>
           <div className='col-lg-6'>
             <img src='/Assets/teamwork.jpg' className='bg-exp-data' style={{ width:'100%' ,height:450}} alt='' />
           </div>
       
         </div>
       </div>
     </div>


{/* count design */}

<div className='section-two count-design'>
<div className='container'>
  <div className='row'>
    <div className='col-lg-4'>
      <div className='count-inner counter-center'>
        <div className='d-flex'>
          <img src='/Assets/place.png' width='70' height='70' alt='' color='white' />
          <h2>
            <CountUp end={50} duration={12} style={{fontSize:40}} /> +
          </h2>
        </div>
        <p>Our Placements</p>
      </div>
    </div>

    <div className='col-lg-4'>
      <div className='count-inner counter-center'>
        <div className='d-flex'>
          <img src='/Assets/premiumbadge.png' width='70' height='68' alt='' />
          <h2>
            <CountUp end={10} duration={12} style={{fontSize:40}} /> +
          </h2>
        </div>
        <p>Awards</p>
      </div>
    </div>

    <div className='col-lg-4'>
      <div className='count-inner counter-center'>
        <div className='d-flex'>
          <img src='/Assets/recruitment.png' width='70' height='70' alt='' />
          <h2>
            <CountUp end={76} duration={12} style={{fontSize:40}} /> +
          </h2>
        </div>
        <p>Recruitment</p>
      </div>
    </div>
  </div>
</div>
</div>


  {/* {mission} */}

  <div className='section herobannermission'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-8'>
              <div className='inner-text'>
                <h1>MISSION & VISION</h1>
                <p  style={{color:'black'}}>Aviation Course and Workforce: Charting Opportunities in the Skies" refers to the strategic planning and development of educational programs and employment prospects within the aviation industry. It emphasizes the exploration and creation of career pathways for individuals aspiring to work in various roles related to aviation.</p>
              
              </div>
            </div>
          </div>
        </div>
       
      </div>

      <div className="  sliderServices  ">
      <div className="container  col-12  ">
        <div className="d-flex align-items-center  justify-content-center   mb-3">
         
        <h1 className='Severour'>Our Services</h1>
        </div>
<div className='row'>

<div className='col-md-6  d-flex justify-content-center  atcentre'>

        <div>
        <div className=' d-flex boxservices'>
        
        <RiArrowRightSLine className='footericon'/><p className=" footerstextservices">EDUCATION AND RECRUITMENT SERVICES</p>
        </div>
        
        
        
        
        <div className=' d-flex boxservices mt-3'>
        <RiArrowRightSLine className='footericon'/><p className=" footerstextservices">IATA TRAINING & AIRPORT MANAGEMENT TRAINING
        </p>
        </div>
        
        <div className=' d-flex boxservices mt-3'>
        <RiArrowRightSLine className='footericon'/><p className=" footerstextservices ">COLLEGE ADMISSION ASSISTANCE
        </p>
        </div>
        
        <div className=' d-flex boxservices mt-3'>
        <RiArrowRightSLine className='footericon'/><p className=" footerstextservices">TEST PREPARATION (IELTS)
        </p>
        </div>
        
        <div className=' d-flex  boxservices mt-3 '>
        <RiArrowRightSLine className='footericon'/><p className=" footerstextservices">CAREER COUNSELING
        </p>
        </div>
        <div className=' d-flex boxservices mt-3 '>
        <RiArrowRightSLine className='footericon'/><p className=" footerstextservices">RECRUITMENT SERVICES
        </p>
        </div>
        </div>
        </div>
        
      <div className='col-md-6  d-flex justify-content-center  atcentre'>
        
        <div className=''>
        <div className=' d-flex boxservices'>
        
        <RiArrowRightSLine className='footericon'/><p className=" footerstextservices">STUDY ABROAD PROGRAMS
        </p>
        </div>
        
        
        
        
        <div className='d-flex  d-flexc boxservices mt-3'>
        <RiArrowRightSLine className='footericon'/><p className=" footerstextservices">SKILL DEVELOPMENT WORKSHOPS</p>
        </div>
        
        <div className='d-flex boxservices mt-3'>
        <RiArrowRightSLine className='footericon'/><p className=" footerstextservices ">TRAINING PROGRAMS
        </p>
        </div>
        
        <div className='d-flex boxservices mt-3'>
        <RiArrowRightSLine className='footericon'/><p className=" footerstextservices">TICKETING & VISA PROCESSING
        </p>
        </div>
        
        <div className=' d-flex boxservices mt-3 mb-5'>
        <RiArrowRightSLine className='footericon'/><p className=" footerstextservices">MEDICAL TOURISM
        </p>
        </div>
       
        
        </div>
        </div>
        
        




        </div>
      </div>
    </div>
<div className='' id='contactus'>
    
<EnquiryForm/>
</div>







      {/* Our Solutions 

      <div className='section solution-slider'>
        <div className='container'>
          <div className='section-slider'>
            <h6>OUR EXPERTISE</h6>
            <h5>Our Solutions</h5>
            <div className='space5'></div>
            <Splide
                        options={{
                            perPage: 4,
                            breakpoints: {
                                991: {
                                    perPage: 3,
                                },
                                767: {
                                    perPage: 2,
                                },
                                640: {
                                    perPage: 1,
                                },
                            },
                            rewind: true,
                            autoplay: true,
                            interval: 6000,
                            // type: 'fade',
                            // speed: 2200
                        }}
                        aria-label="customList">
                        <SplideSlide>
                            <div className="solution-item solution-one">
                                <img src="/images/soution-icon-1.png" alt="partners_logo"
                                    className="img-responsive" />
                                    <div className='solu-heading-tag'>Our MEP Services</div>
                                    <span>Read More →</span>
                            </div>
                        </SplideSlide>
                     

                        <SplideSlide>
                            <div className="solution-item">
                                <img src="/images/solution-Icon-2.png" alt="partners_logo"
                                    className="img-responsive" />
                                     <div className='solu-heading-tag'>Integrated MEP <br/>Contracting</div>
                            </div>
                        </SplideSlide>

                        <SplideSlide>
                            <div className="solution-item">
                                <img src="/images/solution-icon-three.png" alt="partners_logo"
                                    className="img-responsive" />
                                     <div className='solu-heading-tag'>Pre Fabrication</div>
                            </div>
                        </SplideSlide>

                        <SplideSlide>
                            <div className="solution-item">
                                <img src="/images/solution-con-four.png" alt="partners_logo"
                                    className="img-responsive" />
                                     <div className='solu-heading-tag'>Retrofitting Services</div>
                            </div>
                        </SplideSlide>

                        <SplideSlide>
                            <div className="solution-item">
                                <img src="/images/solution-con-four.png" alt="partners_logo"
                                    className="img-responsive" />
                                     <div className='solu-heading-tag'>Retrofitting Services</div>
                            </div>
                        </SplideSlide>

                    </Splide>
          </div>
        </div>
      </div>
      */}

      {/* {Fabrication} 

      <div className='section two-left'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='inner-two'>
                <h6>unleashing MEP Revolution with</h6>
                <div className='space5'></div>
                <h3>Pre-Fabrication</h3>
                <div className='space5'></div>
                <p className='paragraph'>Reimagining onsite execution, VKBS revolutionizes MEP services by integrating manufacturing excellence through pre-fabrication for rapid assembly of defect-free modular components.. Our pioneering template-driven prefab techniques integrate innovation with quality and safety to fast-track projects and maximize customer delight.</p>
                <a href='/'
                  type="button"
                  className="mt-2 btn btn-primary btn-rounded btn-lg">
                  Know More
                </a>
              </div>
            </div>
            <div className='col-lg-6'>
              <img src='/images/left.png' className='bg-exp-data' alt='' />
            </div>
          </div>
        </div>
      </div>


 */}



      {/* expertise 
      <div className='section expertise'>
        <div className='container'>
          <h6>Featured projects</h6>
          <div className='row'>
            <div className='col-lg-6'>
              <h3>See our expertise</h3>
            </div>
            <div className='col-lg-6 text-end'>
              <a href='/'
                type="button"
                className="mt-2 btn btn-primary btn-rounded btn-lg">
                View All Projects
              </a>
            </div>
          </div>
          <div className='space10'></div>
          <div className='row'>
          <div className='col-lg-3'>
            <div className='bg-exp bg-exp-data'>
              <h6>Infrastructure</h6>
              <h3>Lorem ipsum dolor sit amet consectetur.</h3>
              <p>Bangalore</p>
              <a href='/'
                type="button"
                className="mt-2 btn btn-primary-two  btn-lg">
                View 
              </a>
            </div>
           
           </div>
           <div className='col-lg-3'>
              <img src='/images/exp1.png' className='bg-exp-data' alt='' />
           </div>
           <div className='col-lg-3'>
           <img src='/images/exp2.png' className='bg-exp-data' alt='' />
           </div>
           <div className='col-lg-3'>
           <img src='/images/exp3.png' className='bg-exp-data' alt='' />
           </div>
          </div>
        </div>
      </div>
*/}

      {/* Delivery 
      <div className='section two-left'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='inner-two'>
                <h6>MEP Excellence With</h6>
                <h3>Integrated Project Delivery</h3>
                <p className='paragraph'>Our MEP services are powered by Centre of Excellence, Project Control Department, stringent 4-Level Monitoring governance, and Transition Task Force, ensuring seamless integration across project lifecycles. This unified alignment of cross-functional expert teams results in optimized outcomes by collectively driving adherence to critical success benchmarks - safety, quality, timely delivery and perfection - across the entirety of Mechanical, Electrical and Plumbing solutions.</p>
                <a href='/'
                  type="button"
                  className="mt-2 btn btn-primary btn-rounded btn-lg">
                  Know More
                </a>
              </div>
            </div>
            <div className='col-lg-6'>
              <img src='/images/grop-vkbs.png' className='bg-exp-data' alt='' />
            </div>
          </div>
        </div>
      </div>
*/}

      {/* Quality 

      <div className='section quality'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
            <img src='/images/pipe.png' className='bg-exp-data' alt='' />
            </div>
            <div className='col-lg-6'>
            <div className='inner-two'>
                <h6>why us</h6>
                
                <h3>Unwavering Commitment to Quality Delivery</h3>
                <p className='paragraph'>With over 65 million square feet of MEP projects executed, 2 million square feet of EPC development encompassing 2000+ residential units, 6,00,000 square feet of specialized fit-outs and delivery of 2,00,000+ square feet A-grade workspaces - VKBS offers unrivaled competencies spanning integrated design-build services, project management and quality-focused sustainable execution of complex mechanical, electrical and plumbing solutions pan-India, making us the industry's most trusted one-stop MEP services provider.</p>
          
                <a href='/contact-us'
                  type="button"
                  className="mt-2 btn btn-primary btn-rounded btn-lg">
                  Contact Us
                </a>
              </div>

            </div>
          </div>
        </div>
      </div>
      */}


{/* client logo 

    
      <div className='section customlist-section'>
                <div className='container'>
                    <h2 className='primary-text'>Trusted by more than <span>30,000</span> companies</h2>
                    <Splide
                        options={{
                            perPage: 5,
                            breakpoints: {
                                991: {
                                    perPage: 3,
                                },
                                767: {
                                    perPage: 2,
                                },
                                640: {
                                    perPage: 1,
                                },
                            },
                            rewind: true,
                            autoplay: true,
                            interval: 6000,
                            // type: 'fade',
                            // speed: 2200
                        }}
                        aria-label="customList">
                        <SplideSlide>
                            <div className="clientlogo-item">
                                <img src="/images/cisco-logo.png" alt="partners_logo"
                                    className="img-responsive" />
                            </div>
                        </SplideSlide>
                        <SplideSlide>
                            <div className="clientlogo-item">
                                <img src="/images/net-app-logo.png" alt="partners_logo"
                                    className="img-responsive" />
                            </div>
                        </SplideSlide>

                        <SplideSlide>
                            <div className="clientlogo-item">
                                <img src="/images/sify.png" alt="partners_logo"
                                    className="img-responsive" />
                            </div>
                        </SplideSlide>

                        <SplideSlide>
                            <div className="clientlogo-item">
                                <img src="/images/hp.png" alt="partners_logo"
                                    className="img-responsive" />
                            </div>
                        </SplideSlide>

                        <SplideSlide>
                            <div className="clientlogo-item">
                                <img src="/images/ibm.png" alt="partners_logo"
                                    className="img-responsive" />
                            </div>
                        </SplideSlide>

                        <SplideSlide>
                            <div className="clientlogo-item">
                                <img src="/images/hp.png" alt="partners_logo"
                                    className="img-responsive" />
                            </div>
                        </SplideSlide>
                     
                      
                     
                         
                    </Splide>
                </div>
     </div> 


*/}
    </>
  )
}


