// src/App.js
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Home";
import Aviation from "./Aviation";
import Header from "./components/Header";
import './Styles/globals.css';  
import './Styles/homepage.css';  
import './Styles/timeline.css';  
import './Styles/responsive.css';
import './Styles/multiRangeSlider.css'; 
import './Styles/elementor.css'; 
import './Styles/formandpopup.css';  
import Manpower from './Manpower';
import Footer from './components/Footer';

function App() {
  return (
    <div>

    <Header/>
  
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Aviation" element={<Aviation />} />
        <Route path="/Manpower" element={<Manpower />} />
        
      </Routes>
    <Footer/>
    </div>
  );
}

export default App;
