import React, { useRef, useState } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";

const Testmonials = () => {
  const splideRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const goToSlide = (index) => {
    if (splideRef.current) {
      splideRef.current.go(index);
      setActiveIndex(index);
    }
  };

  return (
    <>
      <div className="section solution-slider">
        <div className="container">
          <div className="section-slider text-center">
            <Splide
              options={{
                perPage: 3,
                rewind: true,
                autoplay: true,
                interval: 6000,
                pagination: false,
                gap: "1rem",
                breakpoints: {
                  1200: {
                    perPage: 4,
                    gap: "1rem",
                  },
                  768: {
                    perPage: 2,
                    gap: "0.5rem",
                  },
                  480: {
                    perPage: 1,
                    gap: "0.25rem",
                  },
                },
              }}
              aria-label="customList"
              ref={splideRef}
              onMoved={(splide, newIndex) => setActiveIndex(newIndex)}
            >
              <SplideSlide>
                <div className="solution-item bounce">
                  <div className="imgDiv-carousel">
                    <img
                      src="/Assets/Aviimg.jpg"
                      alt="partners_logo"
                      className="imgDiv-carousel"
                    />
                  </div>
                  <div className="slideSecond">
                    <h6 className="mt-2">Ground Training Package CPL</h6>
                    <p>Duration : 10 Months</p>
                    <p>Fees : 3 L</p>
                  </div>
                </div>
              </SplideSlide>

              <SplideSlide>
                <div className="solution-item bounce">
                  <div className="imgDiv-carousel">
                    <img
                      src="/Assets/Aviimg.jpg"
                      alt="partners_logo"
                      className="img-responsive"
                    />
                  </div>
                  <div className="slideSecond">
                    <h6 className="mt-2">Ground Training Package CPL</h6>
                    <p>Duration : 10 Months</p>
                    <p>Fees : 3 L</p>
                  </div>
                </div>
              </SplideSlide>

              <SplideSlide>
                <div className="solution-item  bounce">
                  <div className="imgDiv-carousel">
                    <img
                      src="/Assets/Aviimg.jpg"
                      alt="partners_logo"
                      className="img-responsive"
                    />
                  </div>
                  <div className="slideSecond">
                    <h6 className="mt-2">Ground Training Package CPL</h6>
                    <p>Duration : 10 Months</p>
                    <p>Fees : 3 L</p>
                  </div>
                </div>
              </SplideSlide>

              <SplideSlide>
                <div className="solution-item bounce">
                  <div className="imgDiv-carousel">
                    <img
                      src="/Assets/Aviimg.jpg"
                      alt="partners_logo"
                      className="img-responsive"
                    />
                  </div>
                  <div className="slideSecond">
                    <h6 className="mt-2">Ground Training Package CPL</h6>
                    <p>Duration : 10 Months</p>
                    <p>Fees : 3 L</p>
                  </div>
                </div>
              </SplideSlide>

              <SplideSlide>
                <div className="solution-item bounce">
                  <div className="imgDiv-carousel">
                    <img
                      src="/Assets/Aviimg.jpg"
                      alt="partners_logo"
                      className="img-responsive"
                    />
                  </div>
                  <div className="slideSecond">
                    <h6 className="mt-2">Ground Training Package CPL</h6>
                    <p>Duration : 10 Months</p>
                    <p>Fees : 3 L</p>
                  </div>
                </div>
              </SplideSlide>
            </Splide>
            {/* Custom indicators 
            <div className="custom-indicators">
              <button
                className={activeIndex === 0 ? "active" : ""}
                onClick={() => goToSlide(0)}
              ></button>
              <button
                className={activeIndex === 1 ? "active" : ""}
                onClick={() => goToSlide(1)}
              ></button>
              <button
                className={activeIndex === 2 ? "active" : ""}
                onClick={() => goToSlide(2)}
              ></button>
              <button
                className={activeIndex === 3 ? "active" : ""}
                onClick={() => goToSlide(3)}
              ></button>
              <button
                className={activeIndex === 4 ? "active" : ""}
                onClick={() => goToSlide(4)}
              ></button>
            </div>
            */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Testmonials;
